<template>
  <div class="MarBox">
    <Header></Header>
    <div class="linkBox">
      <div @click="backHome()" class="linkBoxItem">首页</div>
      <div class="linkBoxItem">招商合作</div>
    </div>
    <div class="tradeTypes" v-show="pageType==='tradeBox'">
      <el-image v-for="(r,index) in tradeTypeList" v-if="tradeTypeList.length>0" :key="index" :src="r.img"
                class="tradeTypes_img" @click="goTrade(r)"
                lazy></el-image>
      <el-empty description="暂无内容" v-if="tradeTypeList.length<1"></el-empty>
    </div>
    <div class="formDiv" v-show="pageType==='formBox'">
      <div class="formDiv_title">{{ titName }}信息通道</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="联系人" prop="name">
          <el-input v-model="form.name" placeholder="请填写联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="form.tel" type="tel" placeholder="请填写联系电话"></el-input>
        </el-form-item>
        <el-form-item label="产品名称" prop="productName">
          <el-input v-model="form.productName" placeholder="请填写产品名称"></el-input>
        </el-form-item>
        <el-form-item label="地址信息" prop="address">
          <!--          <el-input v-model="form.address" placeholder="请填写所在地址"></el-input>-->
          <el-input type="textarea" v-model="form.address" placeholder="请填写所在地址" maxlength="200"
                    show-word-limit></el-input>
        </el-form-item>
        <div class="subBtnBox">
          <el-button type="primary" @click="onSubmit" class="subBtnBox_btn">提交</el-button>
          <el-button @click="backOff()" class="subBtnBox_backBtn">返回</el-button>
        </div>
      </el-form>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {get, post} from "@/utils/request";
import deImg from "@/assets/index/pc-b.jpg";

export default {
  name: "Trade",
  components: {},
  data() {
    return {
      pageType: 'tradeBox',
      tradeTypeList: '',
      form: {
        name: '',
        tel: '',
        productName: '',
        address: ''
      },
      rules: {
        name: [
          {required: true, message: '请填写联系人姓名', trigger: 'blur'}
        ],
        tel: [
          {required: true, message: '请填写联系方式', trigger: 'blur'}
        ],
        productName: [
          {required: true, message: '请填写产品名称', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请填写联系地址', trigger: 'blur'}
        ],
      },
      typeId: '',
      titName: ''
    }
  },
  watch: {
    "$route.query": {
      handler() {
        let datas = this.$route.query;
        // console.log('watch：', datas)
        if (datas.type === 'trade') {
          this.getTradeType()
          this.pageType = 'tradeBox'
        } else {
          this.pageType = 'formBox'
          this.typeId = datas.tid
          this.titName = datas.tit
          if (this.$refs['form']) {
            this.$refs['form'].resetFields()
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    this.getWebInfo()
  },
  methods: {
    async getWebInfo() {
      await get("/api/webPage/getWebPageInfo").then(res => {
        if (res && res.data) {
          let backImg = res.data.backgroundImageUrl || deImg;
          this.setBgImg(backImg)
        } else {
          this.setBgImg(deImg)
        }
      }).catch(() => {
        this.setBgImg(deImg)
      })
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth; // 获取浏览器的宽度
        if (winWidth > 850) {
          let sty = "url(" + img + ") #b4daef no-repeat";
          bodyStyle.background = sty;
          bodyStyle.backgroundAttachment = "fixed";
        } else {
          bodyStyle.background = "#ffffff";
        }
      });
    },
    getTradeType() {
      get("/api/investment/getInvestmentType").then(res => {
        this.tradeTypeList = res.data
      })
    },
    backOff() {
      this.$router.go(-1)
    },
    backHome() {
      this.$router.replace({
        path: "/Index",
      })
    },
    goTrade(record) {
      if (record) {
        this.$router.push({
          path: "/Trade",
          query: {
            tid: record.id,
            type: 'Form',
            tit: record.name
          }
        })
      }
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.typeId = this.typeId
          // console.log(this.form)
          post('/api/investment/submitInvestmentInfo', this.form).then(res => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.$refs['form'].resetFields()
            }, 500)
          })
        } else {
          // console.log('error submit!!');
        }
      });
    }
  }
}
</script>

<style scoped>

.linkBox {
  width: 88%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;
  background-color: #fafafa;
  text-indent: 20px;
  border-radius: 8px;
}

.linkBoxItem {
  height: 38px;
  font-size: 16px;
  font-weight: bold;
}

.linkBoxItem::after {
  content: "/";
  font-size: 20px;
  margin-left: 15px;
  color: #666;
  cursor: unset;
  font-weight: normal;
}

.linkBoxItem:last-child:after {
  color: #fff;
}

.linkBoxItem:hover {
  cursor: pointer;
  color: #0189ec;
}

.tradeTypes {
  width: 84%;
  margin: 0 auto;
  position: relative;
}

.tradeTypes_img {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px auto;
  box-shadow: 0px 1px 10px 0 #585858ba;
}

.tradeTypes_img:hover {
  cursor: pointer;
}

.formDiv {
  width: 84%;
  margin: 5px auto;
  padding: 25px;
}

.formDiv_title {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #222222;
  text-align: left;
  margin-bottom: 23px;
}

.subBtnBox {
  width: 100%;
}

.subBtnBox_btn {
  width: 100%;
  height: 47px;
  font-size: 17px;
}

.subBtnBox_backBtn {
  width: 100%;
  margin: 10px auto;
  height: 45px;
  font-size: 15px;
}

/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .MarBox {
    max-width: 580px;
    min-width: 360px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    position: relative;
  }
}

@media only screen and (max-width: 1000px) {
  .linkBox {
    min-height: 50px;
    height: auto;
  }

  .tradeTypes {
    width: 87%;
  }

  .formDiv {
    width: 86%;
    padding: 20px;
  }
}
</style>
